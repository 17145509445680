<template>
	<div class="container">
		<van-nav-bar safe-area-inset-top :border="false">
			<template #left>
			    <div class="head_left">
			    	<div class="head_left_1"><img src="../../assets/imgs/new_color/logo.png" /></div>
			    	<div class="head_left_2"><img src="../../assets/imgs/new_color/logo_txt.png" /></div>
			    </div>
			</template>
			<template #right>
				<div class="head_right">
					<div class="xiaoxi" @click="tongzhi()"><img class="xiaoxi_Img" src="../../assets/imgs/new_color/tongzhi.png" /></div>
					<div class="yuyanqiehuan" @click="handleChangeLang"><img class="xiaoxi_Img" src="../../assets/imgs/new_color/yuyanqiehuan.png" /></div>
				</div>
			</template>
		</van-nav-bar>

		<div class="head_bgcolor">
			<div class="swipeBanner">
				<van-swipe :autoplay="3000" :show-indicators="false" @change="onChange">
					<van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="lunbo_To(item)">
						<img class="banner" :src="item.url" style="border-radius: 0.16rem;" />
						<!-- <img class="banner" src="@/assets/imgs/index/home_banner.png" /> -->
					</van-swipe-item>
					<template #indicator>
						<div class="custom-indicator">
							<div class="custom-indicator-item" v-for="(item, index) in bannerList" :key="index">
								<img v-if="current != index" src="../../assets/imgs/new_color/xiaoyuandian.png" style="width: 0.06rem; height: 0.06rem;"/>
								<img v-else src="../../assets/imgs/new_color/Stroke@2x.png" style="width: 0.24rem; height: 0.07rem;"/>
							</div>
						</div>
					</template>
				</van-swipe>
			</div>
			<div class="swipeNotice" v-if="noticeList.length">
				<!-- <div>
					<img class="xiaoxi_logo" src="../../assets/imgs/new_color/xiaoxi.png" />
				</div> -->
				<van-swipe :autoplay="3000" :show-indicators="false" vertical>
					<!-- <van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="handleClickArticle(item.id)"> -->
					<van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="routreToParams('consultingService',{active:''+item.cid||'1'})">{{ item.title }}</van-swipe-item>
				</van-swipe>
			</div>
		</div>

		<div class="menuBox">
			<!-- okx开通 -->
			<menu-list :label="$t('system.okx')" linkOutUrl="https://www.okx.com/join/2248628"><img slot="icon" style="width:52%" src="@/assets/imgs/new_color/api-okex.png" alt="" /></menu-list>
			<!-- 火必开通 -->
			<!-- <menu-list :label="$t('system.huobi2')" linkOutUrl="https://www.huobi.com/zh-cn/v/register/double-invite/?inviter_id=11345710&invite_code=k53x6223"><img slot="icon" style="width:73%" src="@/assets/imgs/new_color/huobi.png" alt="" /></menu-list> -->

			<!-- <menu-list :label="$t('system.kuaijiekaihu')" linkUrl="/kaihu"><img slot="icon" src="@/assets/imgs/new_color/kuaijiekaihu.png" alt="" /></menu-list> -->
			<menu-list :label="$t('system.APIAuthorization')" linkUrl="/apiAuthorization2"><img slot="icon" src="@/assets/imgs/new_color/api.png" alt="" /></menu-list>
			<menu-list :label="$t('xinzeng.qianbao')" linkUrl="/assets"><img slot="icon" src="@/assets/imgs/new_color/qianbao.png" alt="" /></menu-list>
			<!-- VIP会员 -->
			<!-- <menu-list :label="$t('system.VIPmember')" linkUrl="/memberClub"><img slot="icon" src="@/assets/imgs/new_color/vip.png" alt="" /></menu-list> -->
			
			<!-- 币种榜单 -->
			<menu-list :label="$t('system.coinRanking')" linkUrl="/coinRankingList"><img slot="icon" src="@/assets/imgs/new_color/vip.png" alt="" /></menu-list>

			<menu-list :label="$t('system.invitefriends')" linkUrl="/sharePage"><img slot="icon" src="@/assets/imgs/new_color/yaoqing.png" alt="" /></menu-list>
			<!-- <menu-list v-if="vipInfo.active" :label="$t('system.invitefriends')" linkUrl="/sharePage"><img slot="icon" src="@/assets/imgs/new_color/yaoqing.png" alt="" /></menu-list>
			<menu-list v-else :label="$t('system.invitefriends')" linkUrl="/tobeVip"><img slot="icon" src="@/assets/imgs/new_color/yaoqing.png" alt="" /></menu-list> -->
			<menu-list :label="$t('system.myteam')" linkUrl="/community2"><img slot="icon" src="@/assets/imgs/new_color/team.png" alt="" /></menu-list>
			<!-- <menu-list :label="$t('system.Log')" linkUrl="/recordsORjournal" :queryParams="{tab:1}"><img slot="icon" src="@/assets/imgs/new_color/rizhi.png" alt="" /></menu-list> -->
			<menu-list :label="$t('system.Bill')" linkUrl="/bill"><img slot="icon" src="@/assets/imgs/new_color/zhangdan.png" alt="" /></menu-list>
			<!-- 托管 -->
			<!-- <menu-list :label="$t('system.trusteeship')" linkUrl="/new_trusteeship"><img slot="icon" src="@/assets/imgs/new_color/tuoguan.png" alt="" /></menu-list> -->
			<!-- 教程 -->
			<menu-list :label="$t('system.tutorial')" linkName="consultingService" :params="{active:'2'}"><img slot="icon" src="@/assets/imgs/new_color/tuoguan.png" alt="" /></menu-list>
		</div>
		<div class="rankBox" v-if="rankList.length">
			<div class="caption">
				<div class="title">{{ $t('team.rankingList') }}</div>
				<!-- <span class="more mainFontLightColor" @click="routerTo('/rankingList2')">{{ $t('home.more') }} <i class="icon_arrR"></i></span> -->
			</div>
			<section class="rankCon">
				<!--  @click="routerTo('/rankingList2')" -->
				<div class="rankItem" style="width: 33%;" v-if="rankList[1]">
					<img class="crown" style="width:32px" src="@/assets/imgs/new_color/two.png" alt="" />
					<img class="headImg" :src="rankList[1].icon" alt="" />
					<p class="name">{{ rankList[1].username }}</p>
					<p class="value">{{ rankList[1].amount }} USDT</p>
					<p class="label">{{ $t('system.today') }}</p>
					<p class="value1">{{ rankList[1].tamount }} USDT</p>
					<p class="label">{{ $t('system.addUp') }}</p>
				</div>
				<div class="rankTop1Con" v-if="rankList[0]">
					<img class="shadow" src="@/assets/imgs/home/shadow.png" alt="" />
					<img class="shadow2" src="@/assets/imgs/home/shadow2.png" alt="" />
					<div class="rankItem guanjun" style="padding-top:6px">
						<img class="crown" style="width:38px" src="@/assets/imgs/new_color/one.png" alt="" />
						<img class="headImg" style="width:32px;height:32px" :src="rankList[0].icon" alt="" />
						<p class="name">{{ rankList[0].username }}</p>
						<p class="value">{{ rankList[0].amount }} USDT</p>
						<p class="label">{{ $t('system.today') }}</p>
						<p class="value1">{{ rankList[0].tamount }} USDT</p>
						<p class="label">{{ $t('system.addUp') }}</p>
					</div>
				</div>
				<div v-if="rankList[2]" class="rankItem1" style="width: 33%;">
					<img class="crown" style="width:31px" src="@/assets/imgs/new_color/three.png" alt="" />
					<img class="headImg" :src="rankList[2].icon" alt="" />
					<p class="name">{{ rankList[2].username }}</p>
					<p class="value">{{ rankList[2].amount }} USDT</p>
					<p class="label">{{ $t('system.today') }}</p>
					<p class="value1">{{ rankList[2].tamount }} USDT</p>
					<p class="label">{{ $t('system.addUp') }}</p>
				</div>
			</section>
		</div>
		<div class="contWhiteBox indexTabList">
			<div class="menuLine">
				<span class="menuItem" :class="{ on: exchange === 'okex' }" @click="handleChangeExchange('okex')">{{$t('system.OKEx')}}</span>
				<span class="menuItem" :class="{ on: exchange === 'huobi' }" @click="handleChangeExchange('huobi')">{{$t('system.huobi')}}</span>
				<span class="menuItem" :class="{ on: exchange === 'bian' }" @click="handleChangeExchange('bian')">{{$t('system.bian')}}</span>
				<!-- <span class="menuItem" :class="{ on: exchange === 'uniswap' }" @click="handleChangeExchange('uniswap')">UNISWAP</span> -->
			</div>
			<div class="menuLine1">
				<!-- 交易方式 -->
				<span class="menuItem" :class="{ on: fangshi == item.value }" @click="jiaoyi_select(item.value)" v-for="(item, index) in menuList" :key="index">
					{{ jiaoyileixing(item.name) }}
				</span>
				<!-- 状态 -->
				<van-popover
					v-model="showPopover2"
					trigger="click"
					:actions="startArr"
					@select="onSelect2"
					placement="bottom-end"
					:offset="[20, 20]"
					style="position: absolute;right: 10px;top: 12.5px;"
				>
					<template #reference>
						<div class="dropdownBtn">
							{{ stateType=='running'? $t('system.running'):$t('system.stop') }}
							<img src="@/assets/imgs/new_color/xiala.png" style="width:11px;vertical-align: middle;" alt="" />
						</div>
					</template>
				</van-popover>
			</div>
			<search style="margin-top:8px" :placeholder="$t('system.Pleaseentercurrency')" @onSearch="handleSearch" />
			<div class="homeList">
				<div v-if="showOrHidden" class="indexLoading">
					<van-loading size="24px">{{ $t('system.Loading') }}</van-loading>
				</div>
				<div v-else>
					<div v-if="computedListData.length">
						<div class="item_head">
							<div class="name">
								<span @click="P_name">{{ $t('system.DealFor') }}</span>
								<img
									src="../../assets/imgs/new_color/paixu.png"
									style="
								     width: 0.3rem;
								 "
								/>
								<!-- <span class="price" @click="P_price">/{{ $t('system.price') }} </span>
					            <img
					             src="../../assets/imgs/index/paixu.png"
					             style="
					                 width: 0.3rem;
					             "
					             /> -->
							</div>
							<div class="bucang">
								<span>{{ $t('system.Replenishmenttimes') }}</span>
							</div>
							<div class="fuyin">
								<span>{{ $t('system.Floating') }}</span>
							</div>
							<div class="fudu">
								<span class="du" @click="P_fudu">{{ $t('system.BBI') }}</span>
								<img
									src="../../assets/imgs/new_color/paixu.png"
									style="
					                 width: 0.3rem;
					             "
								/>
							</div>
						</div>
						<div class="listItem" v-for="(item, index) in computedListData" :key="index" @click="robotStrategy(item)">
							<div class="list_top">
								<div class="listTitle">
									<div class="listTitle_top">
										<div class="top_left">
											<span class="listName">{{ item.currency_pair | namefilter }}</span>
											<span class="listName1">/{{ item.currency_pair | namefilter1 }}</span>
										</div>
										<div class="top_right" :class="{'duo':item.duokong == 1,'kong':item.duokong == 2}" v-if="item.currency_pair.indexOf('/SWAP') != -1">{{duokong_filter(item.duokong)}}x{{item.beishu}}</div>
									</div>
									<div class="listTitle_bottom">
										<div class="new_price">{{ item.last }}</div>
										<div class="listType color_madin">{{ $t('system.' + item.type) }}</div>
									</div>
								</div>
								<div class="listDesc">
									<span>{{ item.times }}/{{ item.times_total }}</span>
								</div>
								<div class="listfuyin">
									<div class="fuying">{{ item.num_ }}</div>
									<div
										v-if="item.type!='wave_f_x_double'"
										class="fuying_dian"
										:class="{
											red: item.color === 'red',
											green: item.color !== 'red'
										}"
									>
										{{ item.win_num }}
									</div>
								</div>
								<div class="listRatio">
									<!-- <div class="listRatio1" :class="{ red: item.color === 'red', green: item.color !== 'red' }">{{ item.ratio }}</div> -->
									<!-- 涨跌概率指数 -->
									<div class="listRatio1" :class="item.score>=50?'green':'red'">{{ item.score }}</div>
								</div>
							</div>
							<div class="list_bottom" v-if="item.error != ''">
								<div class="dian"></div>
								<span class="err_msg">{{ item.error }}</span>
							</div>
						</div>
					</div>
					<!-- <empty v-else :exchange="exchange" style="margin-top: 0.5rem"></empty> -->
					<empty v-else style="margin-top: 0.5rem"></empty>
				</div>
			</div>
		</div>
		<!-- 底部信息栏 -->
		<div class="bottomTips">
			<div class="z_left">{{ $t('system.Usdtavailable') }}: {{ balance }}</div>
			<!-- <div class="z_right" @click="routerTo('/quantification')">{{ $t('system.dealNow') }} ></div> -->
			<div class="z_right" @click="toggleAll">{{ stateType=='running'?$t('system.closeAll'):$t('system.openAll') }} ></div>
		</div>
		<van-tabbar v-model="active" active-color="#EFBA0D" inactive-color="#ACB5BC" route safe-area-inset-bottom>
		  <van-tabbar-item replace to="/">
		    <span>{{ $t('common.home') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/home_a.png" />
			  <img v-else src="../../assets/imgs/new_color/home.png" />
		    </template>
		  </van-tabbar-item>
		 <van-tabbar-item replace to="/quantification">
		    <span>{{ $t('system.quant') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/lianghua_a.png" />
		      <img v-else src="../../assets/imgs/new_color/lianghua.png" />
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item replace to="/my">
		    <span>{{ $t('xinzeng.wode') }}</span>
		    <template #icon="props">
		      <img v-if="props.active" src="../../assets/imgs/new_color/my_a.png" />
		      <img v-else src="../../assets/imgs/new_color/my.png" />
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import search from '@/components/search';
import menuList from './components/menuList';
import rankList from './components/rankList';

import empty from '@/components/empty';
export default {
	name: 'newHome',
	components: {
		menuList,
		rankList,
		empty,
		search
	},
	data() {
		return {
			active: 0,
			scrollY: 0,
			jiaoyiLX: 'spot',
			searchTxt: '',
			stateType: 'running',//running   运行中    all 全部   stop 停止
			showPopover: false,
			showPopover1: false,
			showPopover2: false,
			fangshi: 1,
			bot_list:'',//当前筛选后列表中的机器人id ,分隔
			// active: 'huobi', //当前选择的交易所类型
			name_pai: false,
			price_pai: false,
			Pai: -1,
			showOrHidden: true,
			noticeList: [], //通知列表
			listData: [], //机器人列表
			// ---------20200411[start]--------
			rankList: [], //排行榜列表
			balance: 0, //可用货币余额
			exchange: 'okex', //默认交易所类型,当前选择的交易所类型 huobi
			exchangeMap: {
				huobi: 1,
				bian: 2,
				okex: 3,
				uniswap: 4
			},
			// 轮播图
			bannerList: [],
			vipInfo: {},
			tab_index: 0,
			current: 0,
			banner_index: 0,
		};
	},
	created() {
		// this.getAssets()
		// ---------20200411[end]-------
		// 获取默认交易所信息
		let userinfo = JSON.parse(localStorage.getItem('userInfo'));
		// console.log(userinfo);
		this.exchange = userinfo.default_exchange;

		// 获取缓存的选项信息
		let info = JSON.parse(localStorage.getItem('index_info'));
		if (info != null) {
			this.exchange = info.exchange;
			this.fangshi = info.fangshi;
			this.jiaoyiLX = info.jiaoyiLX;
		}
		console.log(info);

		this.getArticlelist();

		// 如果上次请求时间大于30s则重新请求
		var nowTime = new Date().getTime();
		if (nowTime - this.indexTime > 30000) {
			this.getRobotList();
		}else{
			this.initData(this.exchangeRobotList)
		}

		this.getBannerList();
		// this.getClubMemberInfo();
		// ---------20200411[start]-------
		this.getRankList();
		this.getBalance();
	},
	mounted() {
		this.timer = setInterval(() => {
			this.getRobotList();
		}, 30000);
		window.addEventListener('beforeunload',()=>{
			// 刷新页面时也更新缓存
			this.updateIndexInfo()
		});
	},
	destroyed() {
		// console.log(this.$route);
		clearInterval(this.timer);

		this.updateIndexInfo()
	},
	filters: {
		ratioFormat(value) {
			value = value.replace('%', '');
			let f = parseFloat(value);
			let t = f.toFixed(2) + '%';
			return t;
		},
		namefilter(v) {
			let new_str = v.split('/');
			return new_str[0];
		},
		namefilter1(v) {
			let new_str = v.split('/');
			return new_str[1];
		}
	},
	computed: {
		...mapGetters([
			'menuList',
			'exchangeRobotList',
			'indexTime',
		]),
		startArr(){
			return[
				{ type:'running', text: this.$t('system.running') },
				{ type:'stop', text: this.$t('system.stop') },
			]
		}, 
		sortfudu() {
			return this.sortByKey(this.listData.huobi, 'score','toNumber');
		},
		sortfudu1() {
			return this.sortByKey(this.listData.bian, 'score','toNumber');
		},
		sortfudu2() {
			return this.sortByKey(this.listData.okex, 'score','toNumber');
		},
		sortprice() {
			return this.sortByKey(this.listData.huobi, 'last');
		},
		sortprice1() {
			return this.sortByKey(this.listData.bian, 'last');
		},
		sortprice2() {
			return this.sortByKey(this.listData.okex, 'last');
		},
		computedListData() {
			let data = [];
			let bot_id_list=[];//当前筛选后列表中的机器人id
			// console.log(this.listData)
			if(this.fangshi == 1) {
				// 交易方式筛选 & 运行状态筛选
				data = this.listData[this.exchange].filter(el => {
					if((el.state==(this.stateType=='running'?1:0))&&el.currency.indexOf('_swap') == -1){
						bot_id_list.push(el.bot_id)
						return true
					}
				});
			}else {
				// 交易方式筛选 & 运行状态筛选
				data = this.listData[this.exchange].filter(el => {
					if((el.state==(this.stateType=='running'?1:0))&&el.currency.indexOf('_swap') != -1){
						bot_id_list.push(el.bot_id)
						return true
					}
				});
			}
			if (this.searchTxt) {
				data = data.filter(el => el.token.toUpperCase().indexOf(this.searchTxt) !== -1);
			}
			// console.log(bot_id_list)
			this.bot_list=bot_id_list.join(',')
			return data;
		}
	},
	methods: {
		// 筛选条件更新后-搜索更新 量化列表
		search() {
			var exchange = this.exchange;
			var fangshi = 1;
			var type = 'wave';
			var state = 'all';
			// 获取缓存的头部选项信息
			let info = JSON.parse(localStorage.getItem('head_index_info'));
			if (info != null) {
				exchange = info.exchange;
				fangshi = info.fangshi;
				type = info.type;
				state = info.state;
			}
			this.$post2('Robot/api/index/searchCurrencyV2', {
				exchange,
				trusteeship_type: fangshi,
				type,
				state,
			})
				.then(res => {
					console.log('search', res);
					const arr = _.groupBy(res.list, 'tag');

					this.zone_1_list = arr.MainZone || [];
					this.zone_2_list = arr.SubMainZone || [];
					this.zone_3_list = arr.ETF || [];
					this.zone_4_list = arr.DeFI || [];
					this.zone_5_list = arr.ETP || [];

					// this.total = res.total;
					// this.bot_list = res.list;
					res.list.forEach(item => {
						if (item.bot_id > 0) {
							this.bot_map[item.bot_id] = item.token;
						}
					});

					this.$forceUpdate();
				})
				.catch(e => {});
		},
		updateIndexInfo(){
			// if (this.$route.path == '/circularStrategy') {
				let index_info = {
					exchange: this.exchange,
					fangshi: this.fangshi,
					jiaoyiLX: this.jiaoyiLX,
					fangshi: this.fangshi
				};
				localStorage.setItem('index_info', JSON.stringify(index_info));
			// } else {
			// 	localStorage.removeItem('index_info');
			// }
		},
		// 一键关闭/启动
		toggleAll(){
			// console.log(this.bot_list)
			if(this.bot_list){
				if(this.stateType=='stop'&&this.balance<=0){
					this.$toast.fail(this.$t('system.gasInsufficient'));
					return
				}
				const loading = this.$toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: this.stateType=='running'?this.$t("system.Stoping"):this.$t("system.qidong"),
				});
				this.$post2("Robot/api/index/robotBatchWork", {
					bot_list: this.bot_list,
					state: this.stateType=='running'?0:1,
				})
				.then((res) => {
					loading.clear();
					this.$toast.success(
						this.stateType=='running'?
						this.$t("system.StopSuccess"):
						this.$t("system.qidongsuccess")
					);

					// 更新首页列表
					this.getRobotList()
      				// 更新量化列表
					this.$store.dispatch('setRobotList')
					
					// this.$emit("change", "start");
				})
				.catch((e) => {
					loading.clear();
					this.getRobotList();
					if(e == '请先设置交易所密钥') {
						this.isShowPoster = true;
						return
					}
					if(e == '用户燃料费不足') {
					this.$toast.fail(this.$t('system.gasInsufficient'));
					return
					}
					// this.$toast.fail(e);
					// this.$toast.fail(this.$t('system.' + e));
					this.$toast.fail(e);
				});
			}
		},
		duokong_filter(v) {
			if(v == 1) {
				return this.$t('system.duo')
			}else if(v == 2) {
				return this.$t('system.kong')
			}
		},
		onChange(index) {
			this.current = index;
		},
		handleChangeLang() {
			let lang = this.$i18n.locale;
			// console.log('handleChangeLang', lang)
			if (lang === 'zh') {
				this.$i18n.locale = 'zhf';
				localStorage.setItem('lang', 'zhf');
			}
			if (lang === 'zhf') {
				this.$i18n.locale = 'en';
				localStorage.setItem('lang', 'en');
			}
			if (lang === 'en') {
				this.$i18n.locale = 'kor';
				localStorage.setItem('lang', 'kor');
			}
			if (lang === 'kor') {
				this.$i18n.locale = 'zhf';
				localStorage.setItem('lang', 'zhf');
			}
		},
		jiaoyileixing(e) {
			if (e == '现货') {
				return this.$t('system.xianhuojiaoyi');
			} else if (e == '合约') {
				return this.$t('system.heyuejiaoyi');
			}
		},
		handleSearch(str) {
			if (/^[a-zA-Z]+$/.test(str)) {
				this.searchTxt = str.toUpperCase();
			} else {
				this.searchTxt = str;
			}
		},
		// 状态选择 运行中、已停止
		onSelect2(e) {
			this.stateType = e.type;
			this.getRobotList();
		},
		// 交易方式选择
		jiaoyi_select(e) {
			// this.fangshi = fangshi;
			// this.$toast(this.$t('system.kaifazhong'));
			// return;
			this.fangshi = e;
			if (this.fangshi == 2) {
				this.jiaoyiLX = 'swap';
			} else if (this.fangshi == 1) {
				this.jiaoyiLX = 'spot';
			}
			this.getBalance();
			// this.getRobotList();
		},
		sortByKey(array, key,type) {
			return array.sort((a, b) => {
				let x,y
				if(type=='toNumber'){
					x = Number(a[key]);
					y = Number(b[key]);
				}else{
					x = a[key];
					y = b[key];
				}
				return x < y ? -1 : x > y ? 1 : 0; //小到大
			});
		},
		compare(item1, item2) {
			//item1 ，item2  默认参数  代表arr中的每一个值
			let val1 = item1.currency_pair; //利用item.name进行排序
			let val2 = item2.currency_pair;
			return val1 && val1.localeCompare(val2, 'zh-CN');
			// item1不能为空 ，否则会报localeCompare of undefined
		},
		P_name() {
			this.Pai = 0;
			this.name_pai = !this.name_pai;
			console.log(this.name_pai);
			if (this.name_pai == true) {
				this.listData.huobi = this.listData.huobi.sort(this.compare);
				this.listData.bian = this.listData.bian.sort(this.compare);
				this.listData.okex = this.listData.okex.sort(this.compare);
			} else {
				this.listData.huobi = this.listData.huobi.sort(this.compare).reverse();
				this.listData.bian = this.listData.bian.sort(this.compare).reverse();
				this.listData.okex = this.listData.okex.sort(this.compare).reverse();
			}
		},
		// P_price() {
		//     this.price_pai = !this.price_pai;
		//     console.log(this.price_pai);
		//     if(this.price_pai == true) {
		//         this.listData.huobi = this.sortprice
		// 		this.listData.bian = this.sortprice1
		// 		this.listData.okex = this.sortprice2
		//     }else {
		//         this.listData.huobi = this.sortprice.reverse();
		// 		this.listData.bian = this.sortprice1.reverse();
		// 		this.listData.okex = this.sortprice2.reverse();
		//     }
		// },
		P_fudu() {
			this.Pai = 1;
			this.price_pai = !this.price_pai;
			console.log(this.price_pai);
			if (this.price_pai == true) {
				this.listData.huobi = this.sortfudu;
				this.listData.bian = this.sortfudu1;
				this.listData.okex = this.sortfudu2;
			} else {
				this.listData.huobi = this.sortfudu.reverse();
				this.listData.bian = this.sortfudu1.reverse();
				this.listData.okex = this.sortfudu2.reverse();
			}
		},
		// // 获取资产信息
		// getAssets() {
		//   this.$post2("User/Api/Index/userAsset").then((res) => {
		//     console.log(res);
		//     // 总资产是 amout + frozen

		//     let t = res.USDT.amount + res.USDT.frozen;
		//     // this.assetsDitale = t.toFixed(2);
		//     this.balance = t.toFixed(2);
		//     // let t2 = res.REWARD.amount + res.REWARD.frozen;
		//     // this.rewardData = t2.toFixed(2);
		//     // console.log(typeof res.USDT.amount)
		//     // this.amount =  res.amount
		//     // + res.frozen
		//   });
		// },

		handleChangeExchange(exchange) {
			if (this.exchange !== exchange) {
				this.exchange = exchange;
				// 每次切换交易所,余额都跟随变动
				this.getBalance();
			}
		},
		// ----------20200413[start]---------
		// 轮播跳转
		lunbo_To(item) {
			if(item.type==1){
				console.log(item.to)
				window.open(item.to, '_self')
			}else{
				const regex = /#\/(.*)$/;
    			const match = item.to.match(regex);
				if (match) {
					console.log('/'+match[1])
					this.$router.push('/'+match[1]);
				} else {
					// 没有找到
					this.$router.push('/sharePage');
				}
			}
		},
		// 通知新闻跳转
		handleClickArticle(id) {
			this.$router.push({
				path: '/viewArticle',
				query: {
					id: id
				}
			});
		},
		// ----------20200411[start]---------
		routerTo(linkUrl) {
			this.$router.push(linkUrl);
		},
		routreToParams(name,params) {
			this.$router.push({
				name,
				params
			})
		},
		tongzhi() {
			this.$router.push({
				name: 'consultingService',
				params: {
					active: '3'
				}
			});
		},
		// getClubMemberInfo() {
		// 	this.$post2('User/Api/Index/getClubInfo', {}).then(res => {
		// 		this.vipInfo = res;
		// 		// console.log('vipInfo', this.vipInfo)
		// 	});
		// },
		// 获取公告List
		getArticlelist(size) {
			this.$post2('Article/Api/Index/getHomeArticle', { pagesize: size||1 }).then(res => {
				this.noticeList = res.list;
				
				if(res.total>(size?size:1)){
					this.getArticlelist(res.total)
				}
			});
		},

		// 排行榜列表
		getRankList() {
			this.$post2('Trade/api/index/ranklist', {})
				.then(res => {
					// console.log(res);
					this.rankList = res.list;
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		// 获取-某一个-交易所的可用余额-传参-(交易所exchange=>1:huobi/2:bian/3:okex/4:uniswap)
		getBalance() {
			this.$post2('Robot/Api/Index/getBalance', {
				exchange: this.exchangeMap[this.exchange],
				type: this.jiaoyiLX,
				noToast: true
			})
				.then(res => {
					if (res.USDT.amount !== null) {
						this.balance = res.USDT.amount;
					}
				})
				.catch(e => {
					this.balance = 0
					// console.log("e222: ",e);
					//     this.$toast.clear()
				});
		},
		// ----------20200411[end]---------
		// 初始化-获取交易所机器人列表
		getRobotList() {
			// this.$post2('Robot/Api/Index/getRobotsListWithPrice', {
			// 	state:this.stateType,
			// })
			this.$store.dispatch('setExchangeRobotList',{state:this.stateType})
				.then(res => {
					// if (res.length) {
					this.initData(res)
					// }
				})
				.catch(e => {
					this.showOrHidden = false;
				});
		},
		// 初始化数据 更新数据
		initData(res){
			this.listData = {
				huobi: res.huobi || [],
				bian: res.bian || [],
				okex: res.okex || [],
				uniswap: res.uniswap || []
			};
			this.showOrHidden = false;
			if (this.Pai == 0) {
				if (this.name_pai == true) {
					this.listData.huobi = this.listData.huobi.sort(this.compare);
					this.listData.bian = this.listData.bian.sort(this.compare);
					this.listData.okex = this.listData.okex.sort(this.compare);
				} else {
					this.listData.huobi = this.listData.huobi.sort(this.compare).reverse();
					this.listData.bian = this.listData.bian.sort(this.compare).reverse();
					this.listData.okex = this.listData.okex.sort(this.compare).reverse();
				}
			} else if (this.Pai == 1) {
				if (this.price_pai == true) {
					this.listData.huobi = this.sortfudu;
					this.listData.bian = this.sortfudu1;
					this.listData.okex = this.sortfudu2;
				} else {
					this.listData.huobi = this.sortfudu.reverse();
					this.listData.bian = this.sortfudu1.reverse();
					this.listData.okex = this.sortfudu2.reverse();
				}
			}
		},
		// 获取banner 图
		getBannerList() {
			this.$post2('Pay/api/index/getBannerList ', {})
				.then(res => {
					this.bannerList = res.list;
				})
				.catch(e => {});
		},

		// 跳转交易详情页面
		robotStrategy(item) {
			var robotData={
				...item,
				exchange:this.exchange
			}
			this.$store.commit('setRobotData',robotData)

			this.$router.push({
				path: '/circularStrategy',
				query: {
					bot_id: item.bot_id,
					exchange: this.exchange,
					homepath: '/newhome',
					robot: item.robot_strategy,
					fangshi: this.fangshi
				}
			});
		}
	}
};
</script>

<style lang="less">
.newHomeTwo {
	.van-swipe__indicators {
		width: 22%;
		left: 50%;
		bottom: 0.16rem;
	}

	.van-swipe__indicator {
		height: 0.04rem;
		border-right: 0;
		flex: 1;
	}

	.van-swipe__indicator:not(:last-child) {
		margin-right: 0;
	}
}
</style>
<style lang="less" scoped>
.homeList {
	padding-bottom: 1.5rem;
	padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
	.item_head {
		padding: 0.31rem 0 0.2rem 0;
		display: flex;
		font-family: PingFangSC-Medium;
		justify-content: space-between;
		align-items: center;
		// flex: 1;
		> div {
			padding: 2px 5px;
			background-color: #f3f8fe;
			border-radius: 4px;
		}

		.name {
			// flex: 1.6;
			// width: 30%;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				transform: scale(0.83);
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
			}
		}

		.bucang {
			// flex: 1;
			// width: 1.16rem;
			margin-left: 0.4rem;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f4f4f4;
			// margin-left: 0.5rem;
			span {
				font-size: 0.24rem;
				transform: scale(0.83);
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
		}
		.fuyin {
			// width: 1.16rem;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				transform: scale(0.83);
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
		}

		.fudu {
			// flex: 1;
			// width: 30%;
			// width: 1.16rem;
			// padding: 0.06rem 0;
			padding: 0.06rem 0.15rem;
			display: flex;
			align-items: center;
			background-color: #f4f4f4;
			justify-content: center;
			span {
				font-size: 0.24rem;
				transform: scale(0.83);
				color: #6f7989;
				border-radius: 0.04rem;
				font-weight: 600;
				// margin-left: 0.3rem;
			}
			// .du {
			//     padding-right: 0.15rem;
			// }
		}
	}

	.listItem {
		// position: relative;
		padding: 0.2rem 0 0.2rem 0;
		border-bottom: 0.01rem solid #d3d9de;
		.list_top {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.list_bottom {
			margin-top: 0.1rem;
			display: flex;
			align-items: center;
			.dian {
				margin-top: 0.02rem;
				width: 0.08rem;
				height: 0.08rem;
				background-color: #f5465d;
				border-radius: 50%;
				// margin-right: 0.1rem;
			}
			.err_msg {
				margin-left: -0.12rem;
				color: #f5465d;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
		.listTitle {
			// margin-top: 0.4rem;
			// flex: 1.6;
			// width: 38%;
			width: 35%;
			.listTitle_top {
				// position: relative;
				display: flex;
				// justify-content: space-between;
				// align-items: center;
				align-items: flex-end;
				.top_right {
					margin-left: 0.12rem;
					font-size: 0.24rem;
					transform: scale(0.83);
					padding: 0 0.05rem;
					border-radius: 0.04rem;
					text-align: center;
				}
				.duo {
					background-color: rgba(50, 191, 136, 0.09);
					color: #32BF88;
				}
				.kong {
					background-color: rgba(245, 70, 93, 0.09);
					color: #F5465D;
				}
			}
			.listName {
				font-size: 0.28rem;
				color: #333333;
				font-family: PingFangSC-Medium;
				font-weight: bold;
			}
			.listName1 {
				// padding-left: 0.05rem;
				font-size: 0.24rem;
				// font-weight: bold;
				color: #333;
			}

			.listName_right {
				font-size: 0.12rem;
				font-weight: bold;
				color: #999999;
				padding-left: 0.05rem;
			}

			.listTitle_bottom {
				padding: 0 0.2rem 0.1rem 0;
				display: flex;
				height: 0.5rem;
				align-items: center;
				// justify-content: space-between;

				.new_price {
					color: #6f7989;
					white-space: nowrap;
					line-height: 0.5rem;
					font-size: 0.24rem;
					// font-weight: bold;
				}

				.new_price_red {
					color: #f5465d;
				}

				.new_price_green {
					color: #32bf88;
				}
			}

			.listType {
				height: 0.3;
				line-height: 0.3rem;
				font-size: 0.24rem;
				color: #5eacff;
				border-radius: 0.04rem;
				background-color: #f7faff;
				// padding: 1px 2px;
				margin-left: 0.1rem;
				transform: scale(0.83);
			}
			.color_madin {
				white-space: nowrap;
				border: 0.01rem solid #6f7989;
				color: #6f7989;
				padding: 2px;
			}
			.color_dibu {
				border: 1px solid #00bff1;
				color: #00bff1;
			}
			.color_hailang {
				border: 1px solid #5600f8;
				color: #5600f8;
			}
		}

		.listDesc {
			// flex: 1;
			width: 14%;
			// margin-right: 0.2rem;
			text-align: left;
			white-space: nowrap;

			span {
				font-size: 0.28rem;
				font-weight: 500;
				color: #333;
				// font-weight: bold;
				// margin-left: 0.06rem;
				letter-spacing: 1px;
			}
			// span.red {
			//     color: #CF4E65;
			// }

			// span.green {
			//     color: #07AD91;
			// }
		}

		.fuying {
			color: #333333;
			font-size: 0.28rem;
			// margin-left: 0.25rem;
			// margin-left: 0.05rem;
			font-weight: 500;
			// margin-right: 0.4rem;
		}

		.fuying_dian {
			// padding-top: 0.1rem;
			// margin-left: 0.05rem;
			font-size: 0.24rem;
			// margin-right: 0.4rem;
		}
		.fuying_dian.red {
			color: #f5465d;
		}
		.fuying_dian.green {
			color: #32bf88;
		}
		.listfuyin {
			// flex: 1;
			width: 17%;
			// margin-left: 0.2rem;
			text-align: center;
			// margin-right: 0.35rem;
		}

		.listRatio {
			width: 18%;
			margin-left: 0.4rem;
			border-radius: 0.08rem !important;
			// width: 1.6rem;
		}
		.listRatio1 {
			font-size: 0.24rem;
			font-weight: bold;
			color: #fff;
			padding: 0.12rem;
			text-align: center;
			font-family: PingFangSC-Medium;
		}

		.listRatio1.red {
			background: #f5465d;
			border-radius: 6px;
		}

		.listRatio1.green {
			background: #32bf88;
			border-radius: 6px;
		}
	}
}
</style>

<style scoped lang="less">
// .head {
// 	background-color: #ffffff;
// 	padding: 0.24rem 0.25rem;
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
	.head_left {
		display: flex;
		align-items: center;
		.head_left_1 {
			margin-top: 0.2rem;
			img {
				width: 0.34rem;
				height: 0.4rem;
			}
		}
		.head_left_2 {
			margin: 0.1rem;
			img {
				width: 1.24rem;
				height: 0.15rem;
			}
		}
	}
	.head_right {
		display: flex;
		align-items: center;
		margin-top: 0.2rem;
		.xiaoxi {
			img {
				width: 0.31rem;
				height: 0.36rem;
			}
		}
		.yuyanqiehuan {
			margin-left: 0.59rem;
			img {
				width: 0.36rem;
				height: 0.36rem;
			}
		}
	}
// }
.head_bgcolor {
	background-color: #ffffff;
	padding: 0 0.2rem;
	margin-bottom: 0.2rem;
	padding-top: 0.1rem;
}
.layoutBox {
	padding: 0 0.3rem;
}
.menuBox {
	background-color: #ffffff;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0.28rem 0.2rem 0.28rem 0.2rem;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0.2rem;
	// box-shadow: 0 2px 18px rgba(202, 202, 202, 1);
}
.swipeBanner {
	height: 2.72rem;
	margin-bottom: 0.02rem;
	border-radius: 0.16rem;
	overflow: hidden;
	.banner {
		// width: 7.3rem;
		height: 2.72rem;
		display: block;
		border-radius: 0.16rem;
		overflow: hidden;
	}
	.custom-indicator {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0.1rem;
		display: flex;
		.custom-indicator-item{
			margin: 0 0.06rem;
		}
	}
}
.swipeNotice {
	height: 0.56rem;
	line-height: 0.6rem;
	padding: 0 0.15rem 0 0.65rem;
	background: #fff url(../../assets/imgs/new_color/xiaoxi.png) no-repeat left 0.15rem center;
	background-size: 0.28rem 0.28rem;
	border-radius: 0.1rem;
	// margin-bottom: 0.15rem;
	overflow: hidden;
	color: #14181f;
	font-size: 0.24rem;
	transform: scale(0.916);
	margin-left: -0.4rem;
	.xiaoxi_logo {
		padding-top: 0.18rem;
		margin-right: 0.15rem;
		width: 0.3rem;
		height: 0.3rem;
	}
	.icon_arrR {
		float: right;
		margin: 0.2rem 0.1rem 0 0.2rem;
	}
	.van-swipe-item {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.rankBox {
	padding: 0.2rem;
	// padding-bottom: 0;
	// margin-bottom: 0.25rem;
	background-image: url(../../assets/imgs/new_color/paihang_bg.png);
	background-size: 100% 4.2rem;
	background-repeat: no-repeat;
	position: relative;
	.caption {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: relative;
		height: 0.6rem;
		margin-bottom: 0.8rem;
		// &::before {
		// 	content: '';
		// 	width: .04rem;
		// 	height: .24rem;
		// 	background: #90C4FF;
		// 	border-radius: 2px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;
		// 	margin-top: -.12rem;
		// }
		.title {
			font-family: PingFangSC-Medium;
			font-size: 0.32rem;
			color: #333333;
			font-weight: 600;
			// margin-left: .14rem;
		}
		.more {
			font-size: 0.24rem;
		}
	}
	.list {
		display: flex;
		// justify-content: space-between;
		align-items: flex-end;
	}
}

.indexTabList {
	margin-top: 10px;
	padding: 0 0.31rem 0 0.26rem;
	.tabTit {
		color: #999999;
		font-size: 0.32rem;
		font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
		line-height: 0.7rem;
		height: 0.7rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.1rem;
		text-transform: capitalize;
		li {
			padding-bottom: 0.68rem;
		}
		.on {
			color: #373737;
			font-size: 0.4rem;
			font-weight: bold;
			border-bottom: 1px solid #373737;
		}
	}
}
.menuLine {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.28rem;
		// font-weight: 600;
		color: #8f9eab;
		line-height: 0.6rem;
		// text-align: center;
		// padding: 0.01rem 0 0.02rem;
		// display: inline-block;
		// width: 25%;
		// padding-left: 0.2rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		font-family: PingFangSC-Medium;
		// background: #fc9216;
		// color: #90c4ff;
		font-weight: 600;
		font-size: 0.28rem;
		color: #333333;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}

.menuLine1 {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;
	display: flex;
	align-items: center;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.28rem;
		color: #8f9eab;
		line-height: 0.6rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			padding-right: 0.6rem;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		font-family: PingFangSC-Medium;
		// background: #fc9216;
		// color: #90c4ff;
		font-weight: 600;
		font-size: 0.28rem;
		color: #333333;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}

.indexLoading {
	text-align: center;
	padding-top: 1rem;
}

.bottomTips {
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 1.18rem;
	bottom: calc(env(safe-area-inset-bottom) + 1.18rem);
	height: 0.28rem;
	width: 100%;
	background-color: #f2f2f2;
	padding: 0 0.1rem;
	.z_left {
		font-size: 0.24rem;
		color: #333333;
		transform: scale(0.83);
	}
	.z_right {
		font-size: 0.24rem;
		color: #333333;
		transform: scale(0.83);
	}
}
.list-item0 {
	margin-left: 0.2rem;
}
.list-item2 {
	margin-left: 0.2rem;
}
.container {
	font-family: PingFangSC-Regular;
	background-color: #f5f5f5;
}
.new_list {
	font-size: 12px;
	color: #ffffff;
	padding: 0.2rem 0.4rem 0.2rem 0.3rem;
	border-radius: 6px;
	background-image: linear-gradient(to right, #8ec4ff, #ddacff);
	background-color: #8ec4ff;
	// FILTER: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#8EC4FF, EndColorStr=#DDACFF); /*IE6-IE8*/
	// background-image: -ms-linear-gradient(right, #8EC4FF,  #DDACFF);        /* IE 10 */
	// background-image:-moz-linear-gradient(right, #8EC4FF,#DDACFF);/*火狐*/
	// background-image:-webkit-gradient(linear, 0% 100%, 0% 100%,from(#8EC4FF), to(#DDACFF));/*谷歌*/
	// background-image: -webkit-gradient(linear, 0% 100%, 0% 100%, from(#8EC4FF), to(#DDACFF));      /* Safari 4-5, Chrome 1-9*/
	// background-image: -webkit-linear-gradient(right, #8EC4FF, #DDACFF);   /*Safari5.1 Chrome 10+*/
	// background-image: -o-linear-gradient(right, #8EC4FF, #DDACFF);  /*Opera 11.10+*/
	.leijishouyi {
		margin-bottom: 0.1rem;
	}
	.list_item {
		padding: 0.1rem 0;
		display: flex;
		align-items: center;
		.phone {
			width: 35%;
		}
		.leiji {
			width: 33%;
		}
		.jinri {
			width: 32%;
		}
	}
}
.rankCon {
	height: 2.85rem;
	background-color: #fff;
	border-radius: 6px;
	// display: flex;
	margin-top: -0.5rem;
	position: relative;
	.rankItem {
		position: absolute;
		left: 0;
		top: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			object-fit: cover;
		}
		.name {
			font-family: PingFangSC-Medium;
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #efba0d;
			font-size: 12px;
			font-weight: 600;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
		.label {
			color: #666666;
			font-size: 12px;
			transform: scale(0.83);
		}
		.value {
			font-family: PingFangSC-Medium;
			margin-top: 0.16rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
			// margin-bottom: 8px;
		}
		.value1 {
			font-family: PingFangSC-Medium;
			margin-top: 0.08rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
			// margin-bottom: 8px;
		}
	}
	.rankItem1 {
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			object-fit: cover;
		}
		.name {
			font-family: PingFangSC-Medium;
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #efba0d;
			font-weight: 600;
			font-size: 12px;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
		.label {
			color: #666666;
			font-size: 12px;
			transform: scale(0.83);
		}
		.value {
			font-family: PingFangSC-Medium;
			margin-top: 0.16rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
		}
		.value1 {
			font-family: PingFangSC-Medium;
			margin-top: 0.08rem;
			font-size: 0.24rem;
			color: #303133;
			font-weight: 600;
		}
	}
	.rankTop1Con {
		width: 34%;
		margin: 0 auto;
		position: relative;
		.shadow {
			width: 8px;
			top: 0;
			left: -8px;
			position: absolute;
		}
		.shadow2 {
			width: 8px;
			top: 0;
			right: -8px;
			position: absolute;
		}
		.guanjun {
			background-color: #fff;
			position: absolute;
			top: -15px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			width: 100%;
			bottom: 0;
		}
		.name {
			margin: 0 0.29rem;
			padding-bottom: 0.08rem;
			color: #efba0d;
			font-size: 12px;
			margin-top: 0.2rem;
			font-weight: 600;
			border-bottom: 0.005rem solid rgba(111, 121, 137, 0.39);
		}
	}
}
.tab_bottom {
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 0;
	height: 1.14rem;
	width: 100%;
	background-color: #ffffff;
	border-top: 0.005rem solid #dedede;
	.tab_item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0.14rem 0.8rem;
		.logo {
			width: 0.4rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.logo1 {
			width: 0.32rem;
			height: 0.4rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.txt {
			color: #acb5bc;
			margin-top: 0.04rem;
			font-size: 0.24rem;
			transform: scale(0.916);
		}
		.active {
			color: #efba0d;
			font-family: PingFangSC-Medium;
		}
	}
}
</style>
